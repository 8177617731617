<template>
  <div class="w-100 vh-100 ">
    <div class="custom-logo-icon" style="height:3rem">
      <MyLogo />
    </div>
    <div
      class="bg-color-white h-100 w-100 d-flex justify-content-center align-items-center"
    >
      <div class="custom-box bg-color-white p-2 px-4 py-3">
        <div class="custom-heading font-butler color-black text-center">
          Login to your account
        </div>
        <p class="custom-text text-center my-1 mb-1">
          Please sign-in your account to start listing your property
        </p>
        <div class="custom-input-group d-flex mb-1">
          <input
            type="email"
            name=""
            id=""
            placeholder="Email address"
            v-model="email"
          />
          <div
            class="custom-icon position-relative d-flex justify-content-center align-items-center"
          >
            <Mail />
          </div>
        </div>
        <div class="custom-input-group d-flex mb-1">
          <input type="password" placeholder="Password" v-model="password" />
          <div
            class="custom-icon position-relative d-flex justify-content-center align-items-center"
          >
            <Lock />
          </div>
        </div>
        <div class="text-right">
          <router-link to="/pages/forgotpasswordemail" class="color-blue">
            Forgot your password?
          </router-link>
        </div>
        <button
          class="custom-button btn-full-width color-white bg-color-blue text-uppercase my-2"
          @click="useLoginByEmail"
          v-if="!loading"
        >
          Sign in
        </button>
        <button
          class="custom-button btn-full-width color-white bg-color-blue text-uppercase my-2"
          v-if="loading"
        >
          Loading ...
        </button>
        <div class="text-center">
          <span>New on our platform? </span>
          <router-link to="/pages/registration" class="color-blue">
            Create an account
          </router-link>
        </div>
        <div class="d-flex justify-content-center align-items-center mt-1 mb-2">
          <div class="custom-line"></div>
          <div class="mx-1">or</div>
          <div class="custom-line"></div>
        </div>
        <div class="d-flex justify-content-center">
          <div
            class="custom-icon-social position-relative d-flex justify-content-center align-items-center"
            @click="useLoginByFacebook"
          >
            <img :src="facebookIcon" alt="" />
          </div>
          <div
            class="custom-icon-social position-relative d-flex justify-content-center align-items-center"
          >
            <img :src="twitterIcon" alt="" />
          </div>
          <div
            class="custom-icon-social position-relative d-flex justify-content-center align-items-center"
            @click="useLoginByGoogle"
          >
            <img :src="googleIcon" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from 'firebase/app';
// import 'firebase/auth';
import {
  loginByEmail,
  loginByFacebook,
  loginByGoogle,
  logout,
  userLogin,
  verifyEmailBeta
} from "@/utils/api.js";
import facebookIcon from "@/assets/icons/facebook.png";
import twitterIcon from "@/assets/icons/twitter.png";
import googleIcon from "@/assets/icons/google.png";
import Mail from "@/views/Icons/Mail/Mail.vue";
import Lock from "@/views/Icons/Lock/Lock.vue";
import MyLogo from '@/views/newcomponents/Logo/Logo.vue';

export default {
  data() {
    return {
      email: "",
      password: "",
      facebookIcon,
      twitterIcon,
      googleIcon,
      loading: false,
      error: {
        email: null,
        password: null,
      },
    };
  },

  methods: {
    validateForm() {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        this.error.email = "Please type correct email.";
        this.$toasted.error(this.error.email, {
          position: "top-center",
          action: {
            text: "Cancel",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
        return false;
      }

      if (
        this.password.trim() == null ||
        this.password.trim() == undefined ||
        this.password.trim() == ""
      ) {
        this.error.password = "Please type a valid password.";
        this.$toasted.error(this.error.password, {
          position: "top-center",
          action: {
            text: "Cancel",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
        return false;
      }

      return true;
    },
    async useLoginByEmail() {
      this.error = {
        email: null,
        password: null,
      };
      if (!this.validateForm()) {
        return;
      }
      this.loading = true;
      try{

        const details = {
          email : this.email,
          password : this.password
        }

        // this.$store.commit("user/SET_USER", data);
        // this.$router.push("/pages/userdashboard");

        const resData = await userLogin(details);
        console.log("*&**&",resData);

        if(resData.data.access){
          console.log('Login successful');
          localStorage.setItem('app_access_token', resData.data.access);
          localStorage.setItem('app_refresh_token', resData.data.refresh);
          this.$router.push('/pages/userdashboard');

        }
        else{
          this.$toasted.error(
            resData.data.msg ? resData.data.msg : "Wrong Credentials",
            {
              position: "top-center",
              action: {
                text: "Cancel",
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                },
              },
            }
          );

        }
        if (!resData.data.isVerified) {
            this.$toasted.info(
              "Please check your email to verify your account.",
              {
                position: "top-center",
                action: {
                  text: "Cancel",
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0);
                  },
                },
              }
            );
            let res = await verifyEmailBeta({ email: this.email });
          }


      

      }
      catch (error) {
        console.log(error)
        this.$toasted.error(
          error == "Error: Request failed with status code 401"
            ? "Incorrect email or password."
            : "Some error occured.",
          {
            position: "top-center",
            action: {
              text: "Cancel",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          }
        );
      }
      this.loading = false;
    },

    async useLoginByGoogle() {
      const data = await loginByGoogle();
      if (!data.err) {
        this.$store.commit("user/SET_USER", data);

        this.$router.push("/pages/userdashboard");
      } else {
        window.location.reload();
      }
    },

    async useLoginByFacebook() {
      const data = await loginByFacebook();
      if (!data.err) {
        this.$store.commit("user/SET_USER", data);
        this.$router.push("/pages/userdashboard");
      } else {
        window.location.reload();
      }
    },
  },
  components: {
    Mail,
    Lock,
    MyLogo
  },
};
</script>

<style lang="scss" scoped>
.custom-box {
  height: 35rem;
  width: 30rem;

  box-shadow: 0px 0px 20px -2px rgba(#111, 0.1);
  .custom-heading {
    font-size: 2.3rem;
    font-weight: 500;
  }
  .custom-text {
    font-size: 1.1rem;
    line-height: 1.6;
  }
  .custom-button {
    transition: 0.3s ease;
    &:hover {
      box-shadow: 0px 10px 20px -5px rgba(#0d6efd, 0.5);
      transform: translateY(-1px);
    }
  }
  .custom-input-group {
    height: 2.4rem;
    position: relative;
    input {
      width: 85%;
      height: 100%;
      border: 1.3px solid rgba(#111, 0.3);
      border-right: 0px;
      border-radius: 5px 0px 0px 5px;
      outline: none;
      padding: 0 0.5rem;
      font-size: 0.9rem;
      &::placeholder {
        font-weight: 400;
        color: rgba(#111, 0.3);
        font-size: 0.9rem;
      }
    }
    .custom-icon {
      width: 15%;
      height: 100%;
      border-radius: 0px 5px 5px 0px;
      border: 1.3px solid rgba(#111, 0.3);
      position: relative;
    }
  }
  .custom-line {
    width: 45%;
    height: 1px;
    background-color: rgba(#111, 0.3);
  }
  .custom-icon-social {
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 3px;
    margin-top: -1rem;
    margin-right: 0.5rem;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    img {
      height: 60%;
      width: auto;
    }
    &:nth-child(1) {
      background-color: navy;
    }
    &:nth-child(2) {
      background-color: deepskyblue;
    }
    &:nth-child(3) {
      background-color: red;
    }
  }
}
.custom-logo-icon{
  height: 5rem;
  width: 9rem;
  position: absolute;
  top: 5%;
  left:10%;
  z-index: 100;
}
</style>
