<template>
  <div class="position-relative">
    <img class="custom-icon" :src="lock" />
  </div>
</template>
<script>
import lock from "@/assets/icons/padlock.png";
export default {
  data() {
    return {
      lock,
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.custom-icon {
  height: 1.4rem;
  width: auto;
}
</style>
